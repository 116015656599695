import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '~/services/api';

interface TableViewManagerProps {
  children: ReactNode;
}

export type TableViewManagerData = {
  viewCardSelected: any;
  onChangeViewSelected: (view: any) => void;
  codCardSelected: number | null;
  onChangeCodCardSelected: (cod: number | null) => void;
  findViewByCardClicked: (cod_card: number, cod_tela: number) => Promise<void>;
  codVisao: number | null;
  resetCodCardVisao: () => void;
  updateGrid: boolean;
  onChangeUpdateGrid: (value: boolean) => void;
  onChangeCodVisao: (cod: number | null) => void;
  onResetAllStates: (notreset?: boolean) => void;
  onChangeQtdColunasFixadasNaGrid: (qtd: number) => void;
  qtdColunasFixadas: number;
};

export const TableViewManagerContext = createContext(
  {} as TableViewManagerData,
);

export const TableViewManagerProvider: React.FC<TableViewManagerProps> = ({
  children,
}) => {
  const [viewCardSelected, setViewCardSelected] = useState<any>(null);
  const [codCardSelected, setCodCardSelected] = useState<number | null>(null);
  const [codVisao, setCodVisao] = useState<number | null>(null);
  const [updateGrid, setUpdateGrid] = useState(false);
  const [qtdColunasFixadas, setQtdColunasFixadas] = useState(0);

  const onChangeViewSelected = useCallback((view: any) => {
    setViewCardSelected(view);
  }, []);

  const onChangeCodCardSelected = useCallback((cod: number | null) => {
    setCodCardSelected(cod);
  }, []);

  const resetCodCardVisao = useCallback(() => {
    setCodVisao(null);
    setUpdateGrid(false);
  }, []);

  const onChangeUpdateGrid = useCallback((value: boolean) => {
    setUpdateGrid(value);
  }, []);

  const onChangeCodVisao = useCallback((cod: number | null) => {
    setCodVisao(cod);
  }, []);

  const onResetAllStates = useCallback((notreset?: boolean) => {
    setCodVisao(null);

    if (notreset) {
      setUpdateGrid(true);
    } else {
      setViewCardSelected(null);
      setCodCardSelected(null);
      setUpdateGrid(false);
    }
  }, []);

  const findViewByCardClicked = useCallback(
    async (cod_card: number, cod_tela: number) => {
      const { data } = await api.get(
        `/tela-card/visao/${cod_card}/${cod_tela}`,
      );

      if (data.success) {
        setCodVisao(data.data.cod_visao);
        setViewCardSelected(data.data.visualizacao);
      } else {
        setCodVisao(null);
        setViewCardSelected(null);
      }
    },
    [],
  );

  const onChangeQtdColunasFixadasNaGrid = useCallback((qtd: number) => {
    setQtdColunasFixadas(qtd);
  }, []);

  return (
    <TableViewManagerContext.Provider
      value={{
        viewCardSelected,
        onChangeViewSelected,
        codCardSelected,
        onChangeCodCardSelected,
        findViewByCardClicked,
        codVisao,
        resetCodCardVisao,
        updateGrid,
        onChangeUpdateGrid,
        onChangeCodVisao,
        onResetAllStates,
        onChangeQtdColunasFixadasNaGrid,
        qtdColunasFixadas,
      }}
    >
      {children}
    </TableViewManagerContext.Provider>
  );
};

export const useTableViewManager = (): TableViewManagerData =>
  useContext(TableViewManagerContext);
