import React, { createContext, useState } from 'react';

export interface Screen {
  label: string;
  content?: any;
  active?: boolean;
  lib_icon: string;
  des_icon: string;
  cod_tela: number;
  url: string;
  linkMaterial?: string;
  flg_abre_pesquisa: boolean;
}
export interface IScreen {
  screensInformacoes: Screen[];
  addScreenInfo: (screenInfo: Screen) => void;
  removeScreenInfo: (cod_tela: any) => void;
  removeAllScreenInfo: () => void;
}

const InformacaoTelasAcessadasContext = createContext<IScreen>({} as IScreen);

/**
 * Contexto utilizado para armazenar informações na tela sem precisar chamar
 * navigation e gerar ciclo de dependencias
 * @var screensInformacoes Retorna informações da tela
 */

const InformacaoTelasAcessadasProvider: React.FC = ({ children }) => {
  const [screensInformacoes, setScreensInformacoes] = useState<Screen[]>([]);

  const addScreenInfo = (screenInfo: Screen) => {
    const screen = screensInformacoes.some(
      (ArrayscreenInfo) => ArrayscreenInfo.cod_tela === screenInfo.cod_tela,
    );
    if (!screen) {
      setScreensInformacoes([...screensInformacoes, screenInfo]);
    }
  };
  const removeScreenInfo = (cod_tela: number) => {
    const screenRemoved: Screen[] = screensInformacoes.filter(
      (screen) => screen.cod_tela !== cod_tela,
    );

    if (screenRemoved) {
      setScreensInformacoes(screenRemoved);
    } else {
      setScreensInformacoes([]);
    }
  };

  const removeAllScreenInfo = () => {
    setScreensInformacoes([]);
  };

  return (
    <InformacaoTelasAcessadasContext.Provider
      value={{
        screensInformacoes,
        addScreenInfo,
        removeScreenInfo,
        removeAllScreenInfo,
      }}
    >
      {children}
    </InformacaoTelasAcessadasContext.Provider>
  );
};

export { InformacaoTelasAcessadasProvider, InformacaoTelasAcessadasContext };
