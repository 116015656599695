import React, { createContext } from 'react';
import useLoja, { IUseLoja } from '../hooks/useLoja';

const LojaContext = createContext<IUseLoja>({} as IUseLoja);

const LojaProvider: React.FC = ({ children }) => {
  const { loja, codLoja, changeLoja, handleLoja } = useLoja();

  return (
    <LojaContext.Provider
      value={{
        loja,
        codLoja,
        handleLoja,
        changeLoja,
      }}
    >
      {children}
    </LojaContext.Provider>
  );
};

export { LojaContext, LojaProvider };
