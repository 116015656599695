import React, { createContext } from 'react';

import useMacro, { IUseMacro } from '../hooks/useMacro';

const MacroContext = createContext<IUseMacro>({} as IUseMacro);

const MacroProvider: React.FC = ({ children }) => {
  const { macros, addMacro, getMacro } = useMacro();

  return (
    <MacroContext.Provider
      value={{
        macros,
        addMacro,
        getMacro,
      }}
    >
      {children}
    </MacroContext.Provider>
  );
};

export { MacroProvider, MacroContext };
