import * as Remix from 'react-icons/ri';
import * as FontAwesome from 'react-icons/fa';
import * as Material from 'react-icons/md';
import * as CssG from 'react-icons/cg';
import * as SimpleI from 'react-icons/si';
import * as AntDesign from 'react-icons/ai';
import * as Bootstrap from 'react-icons/bs';
import * as Boxicons from 'react-icons/bi';
import * as Feather from 'react-icons/fi';
import * as VSCodeIcons from 'react-icons/vsc';
import * as Grommet from 'react-icons/gr';
import * as GameIcons from 'react-icons/gi';
import * as IonIcons from 'react-icons/io';

type IconType = {
  [key: string]: any;
};

const icones: IconType = {
  Ri: Remix,
  Fa: FontAwesome,
  Md: Material,
  Cg: CssG,
  Si: SimpleI,
  Ai: AntDesign,
  Bs: Bootstrap,
  Bi: Boxicons,
  Fi: Feather,
  Vs: VSCodeIcons,
  Gr: Grommet,
  Gi: GameIcons,
  Io: IonIcons,
};

export default icones;
