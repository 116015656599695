import React, { createContext } from 'react';

import useFilter, { IUseFilter } from '../hooks/useFilter';

const FilterContext = createContext<IUseFilter>({} as IUseFilter);

const FilterProvider: React.FC = ({ children }) => {
  const {
    filtersContexto,
    AddFilterOrUpdate,
    RemoveAllFilter,
    RemoveAllFilterPages,
    RemoveFilter,
    RemoveFilterisBlock,
    RemoveFilterEmptyByIndex,
    AddFilterDefault,
    filterDefauts,
  } = useFilter();

  return (
    <FilterContext.Provider
      value={{
        filtersContexto,
        AddFilterOrUpdate,
        RemoveAllFilter,
        RemoveAllFilterPages,
        RemoveFilter,
        AddFilterDefault,
        filterDefauts,
        RemoveFilterisBlock,
        RemoveFilterEmptyByIndex,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export { FilterProvider, FilterContext };
