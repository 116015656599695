import React, { createContext, useEffect } from 'react';

import useNavigation, { IUseNavigation } from '../hooks/useNavigation';
import { InformacaoTelasAcessadasProvider } from './InformacaoTelasAcessadas';

const NavigationContext = createContext<IUseNavigation>({} as IUseNavigation);

const NavigationProvider: React.FC = ({ children }) => {
  const {
    screens,
    addScreen,
    removeScreen,
    removeAllScreens,
    loader,
    setScreenActive,
    navmenu,
  } = useNavigation();

  useEffect(() => {
    // toast do navegador para confirmar reload da pagina. Obs: Apenas para ambiente de produção
    if (process.env.NODE_ENV === 'production') {
      window.onbeforeunload = () => {
        return 'Ao atualizar a página, os dados da tela serão perdidos, deseja continuar?';
      };
    }
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        screens,
        addScreen,
        removeScreen,
        removeAllScreens,
        loader,
        setScreenActive,
        navmenu,
      }}
    >
      <InformacaoTelasAcessadasProvider>
        {children}
      </InformacaoTelasAcessadasProvider>
    </NavigationContext.Provider>
  );
};

export { NavigationProvider, NavigationContext };
