import React, { createContext } from 'react';
import useLoading, { IUseLoading } from '../hooks/useLoading';

const loadingContext = createContext<IUseLoading>({} as IUseLoading);

const LoadingProvider: React.FC = ({ children }) => {
  const {
    loading,
    setLoading,
    loadingTableSearch,
    setLoadingTableSearch,
    loadingPageSearch,
    setLoadingPageSearch,
    setLoadingNotificaoPage,
    loadingNotificaoPage,
    loadingNotificaoTable,
    setLoadingNotificaoTable,
    handleLoadingPageSearch,
    handleLoadingPageSearchAll,
    handleLoadingPageSearchRemove,

    handleLoadingTableSearch,
    handleLoadingTableSearchAll,
    handleLoadingTableSearchRemove,
    loadingDepartamento,
    setLoadingDepartamento,
    loadingCategoriaFinanceira,
    setLoadingCategoriaFinanceira,
    loadingNavBarNotificacao,
    setLoadingNavBarNotificacao,
    loadingCard,
    setLoadingCard,
  } = useLoading();

  return (
    <loadingContext.Provider
      value={{
        loading,
        setLoading,
        loadingTableSearch,
        setLoadingTableSearch,
        loadingPageSearch,
        setLoadingPageSearch,
        setLoadingNotificaoPage,
        loadingNotificaoPage,
        loadingNotificaoTable,
        setLoadingNotificaoTable,
        handleLoadingPageSearch,
        handleLoadingPageSearchAll,
        handleLoadingPageSearchRemove,
        handleLoadingTableSearch,
        handleLoadingTableSearchAll,
        handleLoadingTableSearchRemove,
        loadingDepartamento,
        setLoadingDepartamento,
        loadingCategoriaFinanceira,
        setLoadingCategoriaFinanceira,
        loadingNavBarNotificacao,
        setLoadingNavBarNotificacao,
        loadingCard,
        setLoadingCard,
      }}
    >
      {children}
    </loadingContext.Provider>
  );
};

export { loadingContext, LoadingProvider };
